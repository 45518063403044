import { gql, TypedDocumentNode } from '@apollo/client';

interface IGetCategories {
    getCategories: ICategory[];
}

export interface ICategory {
    id: string;
    image: string;
    name: string;
    path: string;
    children: ICategory[];
}

export const GET_ALL_CATEGORIES: TypedDocumentNode<IGetCategories> = gql`
    query GET_ALL_CATEGORIES($storeId: Int){
      getCategories(
        store_id: $storeId
      ) {
        id
        image
        name
        path
        children {
          id
          image
          name
          path
          children {
            id
            image
            name
            path
            children {
              id
              image
              name
              path
            }
          }
        }
      }
    }
`;
