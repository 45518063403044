import React from 'react';
import { useQuery } from '@apollo/client';
import { DocumentTitle } from '@silkpwa/module/react-component/document-title';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { GET_ALL_CATEGORIES, ICategory } from 'graphql/catalog/categories';
import { CategoryCard, IFlatCategory } from 'ui/component/category/category-card';
import styles from './style.css';

const flattenCategories = (categories: ICategory[]) => {
    let flatCategories: IFlatCategory[] = [];
    categories.forEach((category) => {
        if (!category.path.endsWith('default-category')) {
            flatCategories.push(category);
        }
        if (category.children && category.children.length > 0) {
            flatCategories = flatCategories.concat(flattenCategories(category.children));
        }
    });
    return flatCategories;
};

const DefaultCategoryPage = () => {
    const t = usePhraseTranslater();
    const storeConfig = useConfig();
    const { data, loading, error } = useQuery(GET_ALL_CATEGORIES,
        {
            variables:
                { storeId: storeConfig?.store_config?.current_store?.id },
        });

    if (loading) return <p>{t('Loading...')}</p>;
    if (error) return <p>{t('Error loading categories')}</p>;

    const categories = flattenCategories(data?.getCategories ?? []);

    return (
        <div className={styles.categoryGrid}>
            <DocumentTitle title="Product Categories" />
            {categories.map((category: IFlatCategory) => (
                <CategoryCard key={category.id} category={category} />
            ))}
        </div>
    );
};

export { DefaultCategoryPage as default };
