import React from 'react';
import placeholder from 'assets/images/product-placeholder.png';
import styles from './style.css';

export interface IFlatCategory {
    id: string;
    image: string;
    name: string;
    path: string;
}

interface CategoryCardProps {
    category: IFlatCategory;
}

export const CategoryCard: React.FC<CategoryCardProps> = ({ category }) => (
    <div key={category.path} className={styles.categoryItem}>
        <a href={`/${category.path}`}>
            <div className={styles.categoryImageWrapper}>
                <img className={styles.categoryImage} src={category.image ?? placeholder} alt={category.name} />
            </div>
            <div className={styles.categoryName}>{category.name}</div>
        </a>
    </div>
);

export { CategoryCard as default };
